import "./App.css";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import Video from "./LogoHome.mp4";

function App() {
  const [videoEnded, setVideoEnded] = useState(false);
  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  useEffect(() => {
    if (videoEnded) {
      // Reset video for looping when it ends
      videoRef.current.play();
      setVideoEnded(false);
    }
  }, [videoEnded]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <video
        ref={videoRef}
        src={Video}
        autoPlay
        muted // Optional: mute the video for autoplay
        onEnded={handleVideoEnd}
        className="absolute top-0 left-0 w-full h-full object-cover" // Changed to w-full and h-full
      />
      {videoEnded && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center relative z-10"
        >
          <h1 className="text-7xl md:text-[70px] font-bold text-black mb-4 font-sans">
            COMING SOON
          </h1>
          <hr className="w-1/2 mx-auto border-t border-[#DDDDDD] mb-4" />
          <motion.h2
            whileHover={{ color: "#666666" }}
            className="text-4xl md:text-[35px] font-semibold text-black font-sans focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            tabIndex={0}
            aria-label="Wellme Pipes"
          >
            Wellme Pipes
          </motion.h2>
        </motion.div>
      )}
    </div>
  );
}

export default App;
